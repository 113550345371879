.section_wrapper {
    max-width: 914px;
    width: 100%;
    margin: 32px auto;
    // padding: 0px 24px;

    .section_title {

        .collection-list-title {
            color: #002140;
            margin-bottom: 44px;
            font-size: 20px;
            font-family: "Barlow";
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            display: flex;
            justify-content: center;
            @media screen and (max-width:1023px) {
                margin-bottom: 10px;
            }
        }
    }

    .collection_uls {

        display: flex;
        justify-content: space-between;

        .collection_li {
            width: 240px;

            .card_content {
                display: flex;
                justify-content: center;
                color: #007bff;
                text-decoration: none;
                background-color: transparent;

                .collection_card {
                    .collection_card__text {
                        display: flex;
                        justify-content: center;

                        h3 {
                            display: flex;
                            align-items: center;
                            // @media screen  and (max-width: 1023px) {
                            display: -webkit-box;
                            align-items: center;

                            img {
                                padding-left: 10px;
                                margin-top: 3px;
                                @media screen and (max-width:1023px) {
                                    margin-top: -1px;
                                }
                            }

                            // }
                        }
                        .arrow-card {
                            max-width: 30px;
                        }
                        .bold_text {
                            display: flex;
                            font-weight: normal;
                            color: #00214091;
                            text-align: center;
                            font-size: 20px;
                            font-family: Barlow;
                            font-style: normal;
                            line-height: normal;
                            @media screen and (max-width:1023px) {
                                font-size: 14px;
                        }
                        .arrow-card {
                            @media screen and (max-width:1023px) {
                                margin-top: 0px !important;
                            }

                        }
                            span {
                                color: #002140;
                                text-align: center;
                                font-size: 20px;
                                font-family: Barlow;
                                font-style: normal;
                                font-weight: 700;
                                line-height: normal;
                                @media screen and (max-width:1023px) {
                                        font-size: 14px;
                                }
                            }
                        }
                    }

                    img {
                        width: 100%;
                        height: auto;
                        @media screen and (max-width:1023px) {
                            max-width: 95px;
                            margin-top: 0px;
                            margin-top: 0px;

                        }
                    }
                    .arrow-card{
                        @media screen and (max-width:1023px) {
                            width: 16px;
                            position: relative; 
                            top: 2px;
                            /* padding-left: 8px !important; */
                            padding: 0px !important;
                            margin-left: 5px;
                        }
                    }

                }
            }
        }
    }

}